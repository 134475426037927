<template>
  <div class="designs">
    <v-container>
      <h1 class="display-3 text-center my-16">{{ $t('main.designs.title') }}</h1>
      <v-row justify="center">
        <v-col cols="12" xl="8">
          <p class="subtitle-1 mb-5" v-html="$t('main.designs.subtitle')"></p>
          <v-divider class="mb-10"></v-divider>
          <h1 class="display-1 mb-5" v-html="$t('main.designs.snapchat.title')"></h1>
          <p class="subtitle-2" v-html="$t('main.designs.snapchat.info')"></p>
          <v-row class="mb-5">
            <v-col v-for="filter in filters" :key="filter.src" cols="6" sm="4" lg="3">
              <v-card
                hover
              >
                <v-img
                  :src="filter.src"
                ></v-img>

                <v-card-title class="justify-center">
                  {{ filter.name }}
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<!--eslint-disable global-require-->

<script>
export default {
  name: 'Designs',
  data() {
    return {
      filters: [
        {
          name: 'Kristallwelten',
          src: require('../assets/images/designs/Kristallwelten.jpg'),
        },
        {
          name: 'Weer',
          src: require('../assets/images/designs/Weer.jpg'),
        },
        {
          name: 'Terfens',
          src: require('../assets/images/designs/Terfens.jpg'),
        },
        {
          name: 'Volders',
          src: require('../assets/images/designs/Volders.jpg'),
        },
        {
          name: 'Pill',
          src: require('../assets/images/designs/Pill.jpg'),
        },
        {
          name: 'Oberperfuss',
          src: require('../assets/images/designs/Oberperfuss1.jpg'),
        },
        {
          name: 'Kolsass',
          src: require('../assets/images/designs/Kolsass.jpg'),
        },
        {
          name: 'Kuehtai',
          src: require('../assets/images/designs/Kuehtai.jpg'),
        },
        {
          name: 'Wattens',
          src: require('../assets/images/designs/Wattens1.jpg'),
        },
        {
          name: 'Oberperfuss',
          src: require('../assets/images/designs/Oberperfuss2.jpg'),
        },
        {
          name: 'Vomp',
          src: require('../assets/images/designs/Vomp.jpg'),
        },
        {
          name: 'Fritzens',
          src: require('../assets/images/designs/Fritzens.jpg'),
        },
        {
          name: 'Baumkirchen',
          src: require('../assets/images/designs/Baumkirchen.jpg'),
        },
        {
          name: 'Fulpmes',
          src: require('../assets/images/designs/Fulpmes.jpg'),
        },
        {
          name: 'Wattens',
          src: require('../assets/images/designs/Wattens2.jpg'),
        },
      ],
    };
  },
};
</script>
